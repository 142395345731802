import "./src/big_number";
import "./src/buffer";
import "./src/lodash";
import "./src/list";
import "./src/polkadotjs";
import "./src/secretjs";
import "./src/toastr";
import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";

// === ACTIVE STORAGE ===
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

// APP
import "./src/application/arbitrage";
import "./src/application/account";
import "./src/application/button_migration";
import "./src/application/price_alerts";
import "./src/application/_balance";
import "./src/application/_token_button";
import "./src/application/_transactions_table";

// ALEPH ZERO
import "./src/aleph_zero/helpers";
import "./src/aleph_zero/pages/button_swap";
import "./src/aleph_zero/pages/desuntralised";
import "./src/aleph_zero/pages/groups";
import "./src/aleph_zero/pages/zk_snark_for_smooth_brains";
import "./src/aleph_zero/smart_contract_hub/index";
import "./src/aleph_zero/smart_contract_hub/new";
import "./src/aleph_zero/smart_contract_hub/edit";

// SECRET NETWORK
import "./src/secret_network/helpers";
import "./src/secret_network/address_alias";
import "./src/secret_network/block_locker";
import "./src/secret_network/butt_lode";
import "./src/secret_network/button_swap";
import "./src/secret_network/mount_doom";
import "./src/secret_network/password_manager";
import "./src/secret_network/pools";
import "./src/secret_network/smart_contract_interface";
import "./src/secret_network/transactions";

// CUSTOM
import { SECRET_NETWORK } from "./src/secret_network/helpers";
import { SN_BUTT_ID } from "./src/secret_network/constants";

export const HELPERS = {
  cookies: {
    get: (id) => {
      return document.cookie
        .split("; ")
        .find((row) => row.startsWith(`${id}=`))
        ?.split("=")[1];
    },
  },
  dropzone: {
    create: (
      selector,
      acceptedFiles,
      inputSelector,
      maxFileSize,
      csrfToken,
      directUploadUrl
    ) => {
      let headers = {
        "X-CSRF-Token": csrfToken,
      };
      let dropZone = new Dropzone(selector, {
        url: directUploadUrl,
        headers,
        maxFiles: 1,
        maxFileSize,
        acceptedFiles,
        addRemoveLinks: true,
        autoQueue: false,
        dictDefaultMessage: "Drop file here to upload",
        init: function () {
          let myDropzone = this;
          let existingFileUrl = $(inputSelector).val();
          if (existingFileUrl.length) {
            let fileDetails = {
              id: inputSelector,
              name: undefined,
              size: 12345,
              imageUrl: undefined,
              accepted: true,
            };
            if (
              existingFileUrl.split("/smart-contract-hub-development/")[1]
                .length
            ) {
              fileDetails.name = existingFileUrl.split(
                "/smart-contract-hub-development/"
              )[1];
            } else if (
              existingFileUrl.split("/smart-contract-hub-production/")[1].length
            ) {
              fileDetails.name = existingFileUrl.split(
                "/smart-contract-hub-production/"
              )[1];
            }
            myDropzone.files.push(fileDetails);
            myDropzone.options.addedfile.call(myDropzone, fileDetails);
            myDropzone.options.complete.call(myDropzone, fileDetails);
            myDropzone.options.success.call(myDropzone, fileDetails);
          }
        },
      });
      // a. Doesn't get called on showing file on server
      // b. This gets called even when maxfilesexceeded
      // manually check the number of accepted files before uploading
      dropZone.on("addedfile", function (file) {
        if (dropZone.getAcceptedFiles().length == 0) {
          let $button = $(selector)
            .closest("form")
            .find("button[type='submit']");
          $button.find(".loading").text("Uploading file...");
          document.disableButton($button);
          $(selector).attr("data-uploading-file", true);

          // disable form submit button and set text of button to uploading file
          // Set data attribute of input selector to uploading file
          // Afterwards, enable button, change text back to loading if no more uploading
          const upload = new DirectUpload(file, directUploadUrl);
          upload.create((error, blob) => {
            $(selector).attr("data-uploading-file", false);
            if (
              $(selector).closest("form").find('[data-uploading-file="true"]')
                .length == 0
            ) {
              document.enableButton($button);
              $button.find(".loading").text("Loading...");
            }
            if (error) {
              document.showAlertDanger(error);
              dropZone.removeFile(file);
              return;
            } else if (
              dropZone.getAcceptedFiles()[0] &&
              dropZone.getAcceptedFiles()[0].upload.uuid == file.upload.uuid
            ) {
              $(inputSelector).val(HELPERS.storj.downloadUrl(blob.key));
            }
          });
        }
      });
      // This if you don't want the exceeded file shown at all
      dropZone.on("maxfilesexceeded", function (file) {
        dropZone.removeFile(file);
      });
      dropZone.on("removedfile", function () {
        $(inputSelector).val(undefined);
      });
    },
  },
  listeners: {
    listenForTokenSelect: function (scope) {
      $(".tokenList li").on("click", function (e) {
        e.preventDefault();
        let modal = $(e.currentTarget).closest(".tokenList");
        // Hide tokenList
        $(modal).modal("hide");
        // Refresh input and search
        $(modal).find("input.search").val("");
        HELPERS.lists[$(modal).find(".modal-content")[0].id].search();

        scope.updateAfterTokenSelect(e);
      });
    },
    listenForPoolSelect: function (scope) {
      $(".poolList li").on("click", function (e) {
        e.preventDefault();
        let modal = $(e.currentTarget).closest(".poolList");
        // Hide poolList
        $(modal).modal("hide");
        // Refresh input and search
        $(modal).find("input.search").val("");
        HELPERS.lists[$(modal).find(".modal-content")[0].id].search();

        scope.updateAfterPoolSelect(e);
      });
    },
  },
  lists: {},
  protocols: {},
  storj: {
    downloadUrl: (fileName) => {
      let storjPrefix;
      if ($("body.rails-env-development").length) {
        storjPrefix = `jxilw2olwgoskdx2k4fvsswcfwfa/smart-contract-hub-development`;
      } else {
        storjPrefix = `juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production`;
      }
      return `https://link.storjshare.io/s/${storjPrefix}/${fileName}?download=1`;
    },
  },
  swapPaths: {
    netUsdResultOfSwaps: (swapPath, cryptocurrencies) => {
      let toCryptocurrency = cryptocurrencies[swapPath.to_id];
      return BigNumber(swapPath.resultOfSwaps)
        .times(BigNumber(toCryptocurrency.price))
        .shiftedBy(-1 * toCryptocurrency.decimals)
        .minus(swapPath.gas_as_usd);
    },
  },
  copyToClipboard: (selectorId) => {
    // Select elements
    const target = document.getElementById(selectorId);
    const button = target.nextElementSibling;

    // Init clipboard -- for more info, please read the offical documentation: https://clipboardjs.com/
    let clipboard = new ClipboardJS(button, {
      target,
      text: function () {
        return target.innerHTML;
      },
    });

    // Success action handler
    clipboard.on("success", function () {
      var checkIcon = button.querySelector(".bi-check");
      var copyIcon = button.querySelector(".bi-clipboard");

      // Exit check icon when already showing
      if (checkIcon) {
        return;
      }

      // Create check icon
      checkIcon = document.createElement("i");
      checkIcon.classList.add("bi");
      checkIcon.classList.add("bi-check");
      checkIcon.classList.add("fs-2x");

      // Append check icon
      button.appendChild(checkIcon);

      // Highlight target
      const classes = ["text-success", "fw-boldest"];
      target.classList.add(...classes);

      // Highlight button
      button.classList.add("btn-success");

      // Hide copy icon
      copyIcon.classList.add("d-none");

      // Revert button label after 3 seconds
      setTimeout(function () {
        // Remove check icon
        copyIcon.classList.remove("d-none");

        // Revert icon
        button.removeChild(checkIcon);

        // Remove target highlight
        target.classList.remove(...classes);

        // Remove button highlight
        button.classList.remove("btn-success");
      }, 3000);
    });
  },
  // Caching
  getProtocol: async (protocolId) => {
    protocolId = Number(protocolId);
    if (!HELPERS[protocolId]) {
      HELPERS[protocolId] = await $.ajax({
        url: `/protocols/${protocolId}`,
        type: "get",
        dataType: "json",
      });
    }
    return HELPERS[protocolId];
  },
  getRandomUUID: async () => {
    try {
      let response = await $.ajax({
        url: "https://web-crypto.butthub.workers.dev/",
        type: "get",
        dataType: "json",
      });
      return response.randomUUID;
    } catch (err) {
      document.showAlertDanger(err);
    }
  },
  initPoolLists: (listIds) => {
    let options = {
      valueNames: ["pool-address", "pool-deposit-label"],
    };
    listIds.forEach(function (listId) {
      HELPERS.lists[listId] = new List(listId, options);
    });
  },
  initTokenLists: (listIds) => {
    let options = {
      valueNames: ["token-list-address", "token-list-label", "token-list-name"],
    };
    listIds.forEach(function (listId) {
      HELPERS.lists[listId] = new List(listId, options);
    });
  },
  refreshCryptocurrencyPriceChart: async (
    base_id,
    quote_id,
    range,
    chart_id,
    chart_container_id
  ) => {
    $(chart_container_id).attr("data-base-id", base_id);
    $(chart_container_id).attr("data-quote-id", quote_id);
    try {
      await $.ajax({
        url: `/ohlcs/chart?base_id=${base_id}&quote_id=${quote_id}&range=${range}&chart_id=${chart_id}&chart_container_id=${chart_container_id.substr(
          1
        )}`,
      });
    } catch (err) {
      document.showAlertDanger(err);
    }
  },
  removeSpaces: (string) => {
    return string.split(" ").join("");
  },
  setUserAccountMenuToggle: (parentSelector, address, name, logoUrl) => {
    $(`${parentSelector} img.user-address-alias-avatar`).attr("src", logoUrl);
    $(`${parentSelector} .account-name`).text(name);
    $(`${parentSelector} .account-address-abbreviated`).text(
      `${address.substring(0, 3)}...${address.slice(-3)}`
    );
  },
};

document.errors = [];

$(document).ready(function () {
  // Popper tooltips
  $('[data-bs-toggle="tooltip"]').tooltip();

  $(`.menu-item a[href="${window.location.pathname}"]`).addClass("active");
  $(`.menu-item a[href="${window.location.pathname}"]`)
    .closest(".menu-accordion")
    .addClass("show here");

  // Prototype alterations
  Number.prototype.countDecimals = function () {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
    return this.toString().split(".")[1].length || 0;
  };

  // Listeners
  $(".balance-view-button").on("click", async (e) => {
    let selector = e.currentTarget;
    let $button = $(selector);
    document.disableButton(selector);
    let cryptoId = $button.attr("data-cryptocurrency-id");
    try {
      await document.connectKeplrWallet(false);
      if (SECRET_NETWORK.walletAddress) {
        if (SECRET_NETWORK.cryptocurrencies[cryptoId].smart_contract) {
          await window.keplr.suggestToken(
            SECRET_NETWORK.chainId(),
            SECRET_NETWORK.cryptocurrencies[cryptoId].smart_contract.address
          );
        }
        SECRET_NETWORK.updateWalletBalance(cryptoId);
        $(selector).addClass("d-none");
      }
    } catch (err) {
      document.showAlertDanger(err);
    } finally {
      document.enableButton(selector);
    }
  });

  if ($("#modal-notices").length) {
    $(".page-title h1 a").removeClass("d-none");
    $(".page-title .bi-info-circle").on("click", async (e) => {
      e.preventDefault();
      $("#modal-notices").modal("show");
    });
  }

  $(".user-balance-container .bi-arrow-repeat").on("click", async (e) => {
    e.preventDefault();
    let selector = e.currentTarget;
    let cryptoId = $(selector)
      .closest(".user-balance-container")
      .attr("data-cryptocurrency-id");
    try {
      SECRET_NETWORK.updateWalletBalance(cryptoId);
    } catch (err) {
      document.showAlertDanger(err);
    } finally {
      document.enableButton(selector);
    }
  });

  $(".input-group .bi-eye").on("click", function (e) {
    $input = $(e.currentTarget).closest(".input-group").find("input");
    if ($input.attr("type") == "text") {
      $input.attr("type", "password");
    } else {
      $input.attr("type", "text");
    }
  });

  // Intervals
  if ($("#blockchain-stats").length) {
    setInterval(function () {
      document.refreshBlockchainStats();
    }, 30_000);
    document.refreshBlockchainStats();
  }
});

document.refreshBlockchainStats = async () => {
  try {
    let id = $("#blockchain-stats").data("blockchain-id");
    await $.ajax({
      url: `/blockchains/${id}/stats`,
    });
  } catch (err) {
    console.log(err);
  }
};

document.activateKeplr = async () => {
  if ($(".keplr-wallet").length) {
    let keplrSelector = ".keplr-wallet-button";
    $(keplrSelector).removeClass("d-none");
    window.addEventListener("keplr_keystorechange", () => {
      window.location.reload();
    });

    document.querySelectorAll(keplrSelector).forEach((item) => {
      item.addEventListener("click", async () => {
        await document.connectKeplrWallet();
      });
    });
    await document.connectKeplrWallet();
  }
};

// The environment for this always has to be production for the user vip level stuff
// But then does it mean that the user vip level stuff is going to be called every time?
document.connectKeplrWallet = async (getAndSetUserVipLevel = true) => {
  try {
    if (!window.keplrOfflineSigner) {
      document.disableButton(".keplr-wallet-button");
      // Keplr extension injects the offline signer that is compatible with cosmJS.
      // You can get this offline signer from `window.getOfflineSignerOnlyAmino(chainId:string)` after load event.
      // And it also injects the helper function to `window.keplr`.
      // If `window.getOfflineSignerOnlyAmino` or `window.keplr` is null, Keplr extension may be not installed on browser.
      if (!window.getOfflineSignerOnlyAmino || !window.keplr) {
        throw "Please install Keplr extension";
      } else {
        if (window.keplr.experimentalSuggestChain) {
          // This method will ask the user whether or not to allow access if they haven't visited this website.
          // Also, it will request user to unlock the wallet if the wallet is locked.
          // If you don't request enabling before usage, there is no guarantee that other methods will work.
          await window.keplr.enable("secret-4");

          // @ts-ignore
          window.keplrOfflineSigner =
            window.getOfflineSignerOnlyAmino("secret-4");
        } else {
          throw "Please use the recent version of keplr extension";
        }
      }
      $(".keplr-wallet-button").addClass("d-none");
      let keplrDetails = await window.keplr.getKey("secret-4");
      SECRET_NETWORK.walletAddress = keplrDetails.bech32Address;
      SECRET_NETWORK.setUserBlockExplorerLinks();
      $("#keplr-user-menu-toggle").removeClass("d-none");
      $("#keplr-user-menu-toggle .wallet-address").text(
        SECRET_NETWORK.walletAddress
      );
      HELPERS.copyToClipboard("keplr-user-account-menu-wallet-address");
      // Set Keplr name
      HELPERS.setUserAccountMenuToggle(
        "#keplr-user-menu-toggle",
        SECRET_NETWORK.walletAddress,
        keplrDetails.name,
        "https://res.cloudinary.com/hv5cxagki/image/upload/c_scale,dpr_2,f_auto,h_25,q_100/logos/b8thbiihwftyjolgjjz2_dhy5mr"
      );
      // Address Alias: Only want to check this once
      if (!SECRET_NETWORK.addressAlias.gettingAfterKeplrConnect) {
        SECRET_NETWORK.addressAlias.gettingAfterKeplrConnect = true;
        SECRET_NETWORK.addressAlias.searchAndSetUserAddressAlias();
      }

      // Only call this once
      // If the user changes wallets the page will refresh and this will still apply
      // If the user signs out then signs in, everything should look the same
      if (!SECRET_NETWORK.keplrConnectedTriggered) {
        $(document).trigger("keplr_connected", {});
        SECRET_NETWORK.keplrConnectedTriggered = true;
      }
    }
    if (window.keplrOfflineSigner && getAndSetUserVipLevel) {
      SECRET_NETWORK.updateWalletBalance(SN_BUTT_ID);
    }
  } catch (err) {
    document.showAlertDanger(err);
    SECRET_NETWORK.keplrConnectedTriggered = false;
    SECRET_NETWORK.walletAddress = undefined;
    $(document).trigger("keplr_dismissed", {});
  } finally {
    document.enableButton(".keplr-wallet-button");
  }
};

// === FUNCTIONS ===
document.amountToCurrency = function (amount, price) {
  return BigNumber(amount)
    .times(BigNumber(price))
    .toNumber()
    .toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
};

document.applyDecimals = function (amount, decimals) {
  return amount / parseFloat("1" + "0".repeat(decimals));
};

document.delay = async (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, ms);
  });
};

document.disableButton = function (selector) {
  let $button = $(selector);
  $button.prop("disabled", true);
  $button.find(".loading").removeClass("d-none");
  $button.find(".ready").addClass("d-none");
};

document.enableButton = function (selector) {
  let $button = $(selector);
  $button.prop("disabled", false);
  $button.find(".loading").addClass("d-none");
  $button.find(".ready").removeClass("d-none");
};

document.formatHumanizedNumberForSmartContract = function (
  humanizedNumber,
  decimals
) {
  if (humanizedNumber == "") {
    humanizedNumber = "0";
  }
  humanizedNumber = String(humanizedNumber);
  return BigNumber(humanizedNumber.replace(/,/g, ""))
    .shiftedBy(decimals)
    .toFixed();
};

document.humanizeStringNumberFromSmartContract = function (
  stringNumber,
  decimals,
  toFormatDecimals = undefined,
  replaceCommas = false
) {
  stringNumber = String(stringNumber).replace(/,/g, "");
  let amount = BigNumber(stringNumber)
    .shiftedBy(decimals * -1)
    .toFormat(toFormatDecimals);
  if (replaceCommas) {
    amount = amount.replace(/,/g, "");
  }
  return amount;
};

document.prettyPrintJSON = function (json) {
  if (typeof json == "string") {
    json = JSON.parse(json);
  }
  return JSON.stringify(json, undefined, 2);
};
