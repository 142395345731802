$(function () {
  if ($("#price-alerts").length) {
    const PRICE_ALERTS = {
      updateResistancesInterval: undefined,
      init: async () => {
        PRICE_ALERTS.refreshResistancesInterval();
        PRICE_ALERTS.getAndSetResistances();
        $("#play-sound").on("click", function (e) {
          e.preventDefault();
          $("#play-sound").addClass("d-none");
          $("#bird-audio").removeClass("d-none");
        });
      },
      getAndSetResistances: async () => {
        try {
          $.ajax({
            url: "/resistances",
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshResistancesInterval: () => {
        clearInterval(PRICE_ALERTS.updateResistancesInterval);
        PRICE_ALERTS.updateResistancesInterval = setInterval(function () {
          PRICE_ALERTS.getAndSetResistances();
        }, 60_000);
      },
    };
    PRICE_ALERTS.init();
  }
});
